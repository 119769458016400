*,
::before,
::after {
  box-sizing: inherit;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-touch-callout: none;
}

* { font-size: 16px; }

html {
  box-sizing: border-box;
  word-wrap: break-word;
}

body {
  overflow-y: scroll;
}

iframe {
  border: 0;
}

img,
svg {
  display: block;
  max-width: 100%;
  height: auto;
}

svg {
  //fill: currentColor;
}

button,
input,
optgroup,
select,
textarea {
  border-radius: 0;
  color: inherit;
}

a,
button {
  border: none;
  cursor: pointer;
}

::placeholder {
  color: inherit;
  opacity: 1;
}

[data-whatintent='mouse'] :focus,
[data-whatintent='touch'] :focus {
  outline: 0;
}

caption {
  display: table-caption;
  text-align: center;
  font-size: .7rem;
  margin: 0 1.25rem;
}

.center {
  text-align: center;
}

.scrollingIsDisabled {
  position:absolute;
  width:100%;
  overflow:hidden
}